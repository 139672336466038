import React from 'react'
import AuthProvider from './src/providers/AuthProvider/AuthProvider'
import Layout from './src/components/Layout/Layout'
import ApolloClientProvider from './src/providers/ApolloProvider/ApolloClientProvider'
import { Script } from 'gatsby'
import { getCurrentEnvConfig } from './config'
import './src/scss/index.scss'

const HIDE_NAVBAR_FOOTER_PATH_FRAGMENTS = ['/auth/', '/challenge']

// Initiate MathJax on route update if its undefined
const onRouteUpdate = () => {
  if (global.MathJax !== undefined) {
    global.MathJax?.Hub?.Queue(['Typeset', global.MathJax.Hub])
  }
}

/* NOTE:
----------------
  Future us: This function previously made sure all  marketing pages besides 
  qml/demonstrations would scroll to the top on page change. (noting this in 
  case we need to revert) 
*/
const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const hasHash = location.hash !== ''
  const isDemoPath = location.pathname.includes('/qml/demonstrations')
  if (isDemoPath || hasHash) {
    return false
  }
  return true
}

const hideNavBarFooter = (path) => {
  return HIDE_NAVBAR_FOOTER_PATH_FRAGMENTS.some((hidePathFragment) =>
    path.includes(hidePathFragment)
  )
}

const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <ApolloClientProvider>{element}</ApolloClientProvider>
    </AuthProvider>
  )
}

const wrapPageElement = ({ element, props }) => {
  return hideNavBarFooter(props.location.pathname) ? (
    element
  ) : (
    <Layout>
      {element}
      <Script src={getCurrentEnvConfig().widget_url} type="module" />
      <pennylane-help></pennylane-help>
    </Layout>
  )
}

// Export browser configurations
export { onRouteUpdate, shouldUpdateScroll, wrapRootElement, wrapPageElement }
