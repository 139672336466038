export default {
  navbarLeft: [
    {
      name: 'Why PennyLane',
      href: '/research',
      subLinks: [
        { name: 'Features', href: '/features' },
        { name: 'Demos', href: '/qml/demonstrations' },
        { name: 'Datasets', href: '/datasets' },
        { name: 'Performance', href: '/performance' },
        { name: 'Devices', href: '/plugins' },
        { name: 'Teach', href: '/education' },
      ],
    },
    {
      name: 'Getting Started',
      href: '/qml',
      subLinks: [
        {
          name: 'Install',
          href: '/install',
        },
        { name: 'Demos', href: '/qml/demonstrations' },
        { name: 'Codebook', href: '/codebook' },
        { name: 'Challenges', href: '/challenges' },
        {
          name: 'Support',
          href: 'https://discuss.pennylane.ai/',
        },
      ],
    },
    {
      name: 'Documentation',
      href: 'https://docs.pennylane.ai/en/stable/',
      subLinks: [
        {
          name: 'How-to demos',
          href: '/search/?contentType=DEMO&categories=how-to&sort=publication_date',
        },
        {
          name: 'Development guide',
          href: 'https://docs.pennylane.ai/en/stable/development/guide.html',
        },
        {
          name: 'Catalyst',
          href: 'https://docs.pennylane.ai/projects/catalyst/en/stable/',
        },
        {
          name: 'API',
          href: 'https://docs.pennylane.ai/en/stable/code/qml.html',
        },
        { name: 'GitHub', href: 'https://github.com/PennyLaneAI/pennylane' },
      ],
    },
    {
      name: 'Ecosystem',
      href: '/get-involved',
      subLinks: [
        { name: 'Blog', href: '/blog/?page=1' },
        {
          name: 'Glossary',
          href: '/qml/glossary',
        },
        {
          name: 'Videos',
          href: '/qml/videos',
        },
        {
          name: 'FAQs',
          href: '/faq',
        },
        { name: 'GitHub', href: 'https://github.com/PennyLaneAI/pennylane' },
        { name: 'Support', href: 'https://discuss.pennylane.ai/' },
      ],
    },
  ],
  navbarRight: {
    buttonLink: {
      text: 'Install',
      link: '/install',
    },
  },
  signInCard: {
    description:
      'Welcome to PennyLane! To make the most of your experience, sign in or create a free account with us.',
    buttonText: 'Sign in',
    createAccountText: 'Create a free account',
    bottomLinks: [
      { link: '/terms', text: 'Terms of service' },
      { link: '/privacy', text: 'Privacy policy' },
    ],
  },
  mobileButton: {
    text: 'Install',
    link: '/install',
  },
}
