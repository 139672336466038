import { useMemo } from 'react'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'isomorphic-fetch'
import { useAuth0 } from '@auth0/auth0-react'
import { getCurrentEnvConfig } from '../../../config'

const config = getCurrentEnvConfig()

const ApolloClientProvider = ({ children }: { children: JSX.Element }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const client = useMemo(() => {
    const httpLinkChallengeSystem = new HttpLink({
      uri: config.graphql,
      fetch,
    })

    const authLink = setContext(async (_, { headers }) => {
      let token = ''

      if (isAuthenticated) {
        try {
          token = await getAccessTokenSilently({
            authorizationParams: {
              audience: config.auth0.audience,
            },
          })
        } catch (e) {
          console.error(e)
        }
      }

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    })

    return new ApolloClient({
      link: from([authLink, httpLinkChallengeSystem]),
      cache: new InMemoryCache({
        possibleTypes: {
          Content: [
            'GenericContent',
            'StandaloneChallengeContent',
            'ProfileContent',
          ],
        },
      }),
    })
  }, [isAuthenticated])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloClientProvider
