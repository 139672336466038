import { spotlightTheme } from './themes'

/**
 * @title spotlights
 * @description This file contains the spotlights for the PennyLane website.
 *
 * Directory:
 * Some helpful links for configuring spotlights:
 * ----------------------------------------------
 * @link Readme file://./README.md}
 * Documentation on how to configure spotlights.
 * @link Themes file://./themes.ts}
 * Themes are used to style the spotlights.
 * @link Templates file://./templates.md}
 * Templates are copy and paste-able code snippets that can be used to create new spotlights.
 */

export default {
  /**
   * @title home_page
   * @description
   * This spotlight is located on the home page of the website.
   * @link https://pennylane.ai/
   */
  home_page: {
    hidden: false,
    announcements: [
      {
        title: 'FRENCH CODEBOOK MODULES NOW AVAILABLE',
        description:
          "As a proudly Canadian quantum software framework, we're excited to now offer five modules of the PennyLane Codebook in French.",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Check them out!',
            link: '/codebook/01-introduction-to-quantum-computing/01-all-about-qubits/fr/',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
    ],
  },

  /**
   * @title education_page
   * @description This located on the education page of the website.
   * @link https://pennylane.ai/education/
   */
  education_page: {
    hidden: false,
    announcements: [
      {
        title: 'FRENCH CODEBOOK MODULES NOW AVAILABLE',
        description:
          "As a proudly Canadian quantum software framework, we're excited to now offer five modules of the PennyLane Codebook in French.",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Check them out!',
            link: '/codebook/01-introduction-to-quantum-computing/01-all-about-qubits/fr/',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
    ],
  },

  /**
   * @title learn_page
   * @description This spotlight is located on the learn page of the website.
   * @link https://pennylane.ai/qml/
   */
  learn_page: {
    hidden: false,
    announcements: [
      {
        title: 'NEW PENNYLANE CODEBOOK MODULES',
        description:
          'Explore Hamiltonian Time Evolution and control the Time Evolution of qubits. Want more? Control your qubits in Distance Measures.',
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Check it out!',
            link: '/codebook/',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
    ],
  },

  /**
   * @title performance_page
   * @description This spotlight is located on the performance page of the website.
   * https://pennylane.ai/performance/
   */
  performance_page: {
    hidden: false,
    announcements: [
      {
        title: 'PERFORMANCE',
        description:
          'PennyLane is faster than ever. Learn how to implement large-scale simulations on supercomputers and GPUs with our latest paper and blog post.',
        image:
          'https://assets.cloud.pennylane.ai/datasets/spotlight/Spotlight_HPC4UandMe.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Read the paper',
            link: 'https://arxiv.org/abs/2403.02512',
          },
          {
            link_text: 'Check out the blog',
            link: 'https://pennylane.ai/blog/2024/03/hpc-4-u-and-me/',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
    ],
  },

  /**
   * @title install_page
   * @description This spotlight is located on the install page of the website.
   * @link https://pennylane.ai/install/
   */
  install_page: {
    hidden: false,
    announcements: [
      // {
      //   title: 'SURVEY CLOSES OCTOBER 31, 2024',
      //   description:
      //     'The Unitary Fund 2024 Quantum Open Source Software Survey is here! Share your voice to help build a better quantum computing ecosystem.',
      //   image:
      //     'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_UnitaryFundSurvey.png',
      //   image_right_aligned: false,
      //   alt: '',
      //   links: [
      //     {
      //       link_text: 'Take the survey',
      //       link: 'https://www.surveymonkey.com/r/qosssurvey24',
      //     },
      //   ],
      //   colours: spotlightTheme.theme_1,
      // },
    ],
  },

  /**
   * @title get_involved_page
   * @description This spotlight is located on the get involved page of the website.
   * @link https://pennylane.ai/get-involved/
   */
  get_involved_page: {
    hidden: true,
    announcements: [
      {
        title: 'PENNYLANE SPRING BADGE CHALLENGE',
        description:
          'April 11–30 | Complete all beginner & novice challenges to earn the *Good Day Sunshine* limited-edition badge.',
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLBadge_GoodDaySunshine_2024-04-04.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Earn this limited-edition badge now',
            link: 'https://pennylane.ai/challenges/',
          },
          {
            link_text: 'Prep with the Getting Started challenge',
            link: 'https://pennylane.ai/challenges/getting_started/',
          },
        ],
        colours: spotlightTheme.theme_1,
      },
    ],
  },

  /**
   * @title features_page
   * @description This spotlight is located on the features page of the website.
   * @link https://pennylane.ai/features/
   */
  features_page: {
    hidden: false,
    announcements: [
      // {
      //   title: 'SURVEY CLOSES OCTOBER 31, 2024',
      //   description:
      //     'The Unitary Fund 2024 Quantum Open Source Software Survey is here! Share your voice to help build a better quantum computing ecosystem.',
      //   image:
      //     'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_UnitaryFundSurvey.png',
      //   image_right_aligned: false,
      //   alt: '',
      //   links: [
      //     {
      //       link_text: 'Take the survey',
      //       link: 'https://www.surveymonkey.com/r/qosssurvey24',
      //     },
      //   ],
      //   colours: spotlightTheme.theme_1,
      // },
    ],
  },

  /**
   * @title codebook_page
   * @description This spotlight is located on the codebook page of the website.
   * @link https://pennylane.ai/codebook/
   */
  codebook_page: {
    hidden: false,
    announcements: [
      {
        title: 'FRENCH CODEBOOK MODULES NOW AVAILABLE',
        description:
          "As a proudly Canadian quantum software framework, we're excited to now offer five modules of the PennyLane Codebook in French.",
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Check them out!',
            link: '/codebook/01-introduction-to-quantum-computing/01-all-about-qubits/fr/',
          },
        ],
        colours: spotlightTheme.theme_2,
      },
    ],
  },

  /**
   * @title challenges_page
   * @description This spotlight is located on the challenges page of the website.
   * @link https://pennylane.ai/challenges/
   */
  challenges_page: {
    hidden: false,
    announcements: [
      // {
      //   title: 'SURVEY CLOSES OCTOBER 31, 2024',
      //   description:
      //     'The Unitary Fund 2024 Quantum Open Source Software Survey is here! Share your voice to help build a better quantum computing ecosystem.',
      //   image:
      //     'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_UnitaryFundSurvey.png',
      //   image_right_aligned: false,
      //   alt: '',
      //   links: [
      //     {
      //       link_text: 'Take the survey',
      //       link: 'https://www.surveymonkey.com/r/qosssurvey24',
      //     },
      //   ],
      //   colours: spotlightTheme.theme_1,
      // },
    ],
  },
  /**
   * @title research_page
   * @description This spotlight is located on the research page of the website.
   * @link https://pennylane.ai/research/
   */
  research_page: {
    hidden: true,
    announcements: [
      {
        title: 'SURVEY CLOSES OCTOBER 31, 2024',
        description:
          'The Unitary Fund 2024 Quantum Open Source Software Survey is here! Share your voice to help build a better quantum computing ecosystem.',
        image:
          'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_UnitaryFundSurvey.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Take the survey',
            link: 'https://www.surveymonkey.com/r/qosssurvey24',
          },
        ],
        colours: spotlightTheme.theme_1,
      },
    ],
  },
}
