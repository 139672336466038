import { graphql, useStaticQuery } from 'gatsby'

export const usePathPrefix = () => {
  const data = useStaticQuery<Queries.GetPathPrefixQuery>(graphql`
    query GetPathPrefix {
      site {
        pathPrefix
      }
    }
  `)

  return data?.site?.pathPrefix ? data.site.pathPrefix : ''
}
