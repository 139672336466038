const authors = [
  {
    name: 'Alain Delgado',
    bio: 'Alain Delgado Gran is a senior quantum scientist working at Xanadu. He has a background in quantum many-body systems and electronic structure methods. His work focuses on the application of quantum algorithms for simulating advanced materials.',
    image: '/images/authors/alain_delgado.jpg',
  },
  {
    name: 'Aleksei Malyshev',
    bio: 'Aleksei is a DPhil student at the University of Oxford. He does research at the intersection of deep learning and quantum physics. In particular, he studies how to make neural networks represent quantum states of interest and how to extract useful information out of this representation. Apart from that, he is not that scared of the quantum winter because he is really keen to see what quantum Santa is like.',
    image: '/images/authors/malyshev.jpg',
  },
  {
    name: 'Alex Otto',
    bio: 'Alex is a product manager at Xanadu. Coming from a background in mathematical physics, he now works on exploring new product opportunities to make PennyLane.ai the premier destination for quantum computing, and towards the goal of making quantum computing accessible to everyone.',
    image: '/images/authors/alex_otto.png',
  },
  {
    name: 'Alvaro Ballon',
    bio: 'Alvaro is a quantum computing educator at Xanadu. His work involves making the latest developments in quantum computing accessible to the community.',
    image: '/images/authors/alvaro_ballon.png',
  },
  {
    name: 'Andrea Mari',
    bio: 'Andrea obtained a PhD in quantum information theory from the University of Potsdam (Germany). He worked as a postdoc at Scuola Normale Superiore (Pisa, Italy) and as a remote researcher at Xanadu. Since 2020 is a Member of Technical Staff at Unitary Fund.',
    image: '/images/authors/andrea_mari.jpeg',
  },
  {
    name: 'Angus Lowe',
    bio: 'Angus is currently a PhD student in physics at the Massachusetts Institute of Technology. His main research interests are in the theory of quantum computation and quantum information.',
    image: '/images/authors/angus_lowe.jpg',
  },
  {
    name: 'Antal Szava',
    bio: 'Antal is a senior quantum software developer at Xanadu working on PennyLane. In his spare time he enjoys watching TV shows, being active outside with friends and learning about acting.',
    image: '/images/authors/antal_szava.jpg',
  },
  {
    name: 'Anuj Apte',
    bio: 'Anuj is a PhD student at the University of Chicago. His research interests include quantum field theory with applications to topological phases and quantum computing.',
    image: '/images/authors/anuj_apte.jpg',
  },
  {
    name: 'Aroosa Ijaz',
    bio: 'Aroosa is a graduate student in quantum computing at the University of Waterloo and the Vector Institute of Artificial Intelligence. Her life interests include quantum physics, machine learning, metal music, traveling, and philosophy!',
    image: '/images/authors/aroosa_ijaz.jpeg',
  },
  {
    name: 'Ben Castanon',
    bio: 'Ben Castanon is the Chief of Staff at the Unitary Fund, a non-profit helping create a quantum technology ecosystem that benefits the most people.',
    image: '/images/authors/ben_castanon.jpg',
  },
  {
    name: 'Bob Coecke',
    bio: 'Bob Coecke is chief scientist at Quantinuum and professor emeritus at Oxford University. Bob pioneered QNLP and diagrammatic reasoning for quantum computing, the most used instance of which is the ZX calculus.',
    image: '/images/authors/bob_coecke.png',
  },
  {
    name: 'Borja Requena',
    bio: 'Borja is a PhD student at ICFO - The Institute of Photonic Sciences, where he develops machine learning algorithms to study physics. He is also a summer resident at Xanadu.',
    image: '/images/authors/borja_requena.jpg',
  },
  {
    name: 'Brian Doolittle',
    bio: 'Brian is a Physics PhD Candidate at the University of Illinois at Urbana-Champaign. Their research focuses upon using variational optimization to better understand quantum communication networks.',
    image: '/images/authors/brian_doolittle.jpg',
  },
  {
    name: 'Carlos E. Lopetegui-Gonzalez',
    bio: 'Carlos is a master’s student at the Ecole Normale Superieur in Paris. His research focuses on quantum optics and quantum information. He is currently working with the Architecture team at Xanadu as part of the summer Residency Program.',
    image: '/images/authors/carlos_gonzalez.webp',
  },
  {
    name: 'Catalina Albornoz',
    bio: 'Catalina is Quantum Community Manager at Xanadu, where she helps build the community around PennyLane. She works with professors from around the world, helping them include quantum programming in their courses.',
    image: '/images/authors/catalina_albornoz.png',
  },
  {
    name: 'Charlie London',
    bio: 'Charlie London is a theoretical computer scientist and software developer. At Quantinuum, Charlie has been working on QNLP experiments and recently developed the PennyLane module in lambeq.',
    image: '/images/authors/charlie_london.jpg',
  },
  {
    name: 'Chase Roberts',
    bio: 'Chase is a software engineer mainly focused on HPC applications. He enjoys fast computers and his cats.',
    image: '/images/authors/chase_roberts.png',
  },
  {
    name: 'Christina Lee',
    bio: 'Christina is currently a quantum software developer at Xanadu working on PennyLane. Outside of coding, she enjoys running, yoga, and piano.',
    image: '/images/authors/christina_lee.jpg',
  },
  {
    name: 'Clara Ferreira Cores',
    bio: 'Clara Ferreira Cores is a quantum physicist working at Molecular Quantum Solutions. Her focus is on using tensor networks to enhance the performance of hybrid quantum-classical algorithms.',
    image: '/images/authors/clara_ferreira_cores.jpeg',
  },
  {
    name: 'Daniela Becker',
    bio: 'Daniela Becker is a Senior Product Manager at Amazon Braket, a service designed to accelerate scientific research and software development for quantum computing. She is excited about helping customers leverage AWS to explore and experiment with quantum hardware. Prior to AWS, Daniela worked in the security & privacy space as a Research Scientist. She received her PhD in Computer Science from the Hamburg University of Technology (Germany) and her MBA from Carnegie Mellon University (USA).',
    image: '/images/authors/daniela_becker.jpeg',
  },
  {
    name: 'David Wakeham',
    bio: "David's elements formed from exploding stars billions of years ago but self-organized into a human more recently. He has a background in string theory and particle physics, but decided his life would be better spent making colourful widgets. His personal website is hapax.github.io.",
    image: '/images/authors/david_wakeham.png',
  },
  {
    name: 'David Wierichs',
    bio: 'David is a researcher and quantum software developer at Xanadu, who likes to think about quantum gradients and how to use them in variational quantum algorithms. He enjoys sharing ideas in science and open source software and likes implementing cool stuff in PennyLane.',
    image: '/images/authors/david_wierichs.jpg',
  },
  {
    name: 'Davide Castaldo',
    bio: 'Davide is a PhD student at the University of Padova where he is studying how to use quantum computers to simulate molecular systems. Currently he is also working at Xanadu as part of the residency program. He is a firm believer that one day raccoons will take over humans in Toronto.',
    image: '/images/authors/davide_castaldo.jpg',
  },
  {
    name: 'Diego Guala',
    bio: 'Diego is a quantum scientist working at Xanadu. His work is focused on developing and implementing quantum algorithms for industrial applications.',
    image: '/images/authors/diego_guala.jpg',
  },
  {
    name: 'Elies Gil-Fuster',
    bio: 'Elies is a QML researcher in Berlin interested in board games and the maths of data re-uploading PQCs. He was a Xanadu Summer Resident of the first cohort, and you can find more about him at https://eliesgilfuster.eu.',
    image: '/images/authors/elies_gil-fuster.jpg',
  },
  {
    name: 'Emiliano Godinez',
    bio: 'Emiliano spends his days balancing his thesis work for the quantum science master at Technical University of Munich, his work as a quantum engineer at IQM, and his passion for football (soccer). His research interests range from simulations of open quantum systems and tensor networks methods, to (quantum) software development. He is always eager to learn about cool proofs and coding tricks! You can find some of his projects at github.com/EmilianoG-byte.',
    image: '/images/authors/emiliano_godinez.jpg',
  },
  {
    name: 'Erik van Mulligen',
    bio: 'Erik combines the role of leading the data science team of S[&]T in Delft with an academic position at the ErasmusMC. As such, he has an interest in new developments, particularly in the field of machine learning applied to satellite images and texts.',
    image: '/images/authors/erik_van_mulligen.jpg',
  },
  {
    name: 'Esteban Aguilera',
    bio: 'Esteban works at TNO as Consultant on Quantum Applications. He has a degree in Computer Science and a Ph.D. in the field of airborne and spaceborne radar imaging. His research interests are anything and everything related to quantum, space technologies, relativistic mechanics, machine learning, optimization, and imaging. He loves programming and he is also the co-founder of Sensar BV.',
    image: '/images/authors/esteban_aguilera.jpg',
  },
  {
    name: 'Esther Cruz-Rico',
    bio: 'Esther is a graduate in physics and mathematics, currently pursuing her PhD in quantum algorithms at the Max Planck Institute of Quantum Optics.',
    image: '/images/authors/esther_cruz.jpeg',
  },
  {
    name: 'Gideon Uchehara',
    bio: 'Gideon is a PhD student at the University of British Columbia, Canada. His research is in quantum algorithms and quantum machine learning. He works at Xanadu as a Quantum Computing Educator Intern. He recently started the Blacks In Quantum (BIQ) initiative and also serves as the student representative on the International Program Committee for the Quantum Computing NSERC CREATE Program, British Columbia, Canada.',
    image: '/images/authors/gideon_uchehara.jpg',
  },
  {
    name: 'Guillermo Alonso',
    bio: 'Guillermo is a mathematics and computer science graduate from the university of Valladolid and is currently working as an educator and quantum researcher at Xanadu. Fun fact, Guillermo is a great foosball player and is also a paella master.',
    image: '/images/authors/guillermo_alonso.jpeg',
  },
  {
    name: 'Isaac De Vlugt',
    bio: 'Isaac De Vlugt is a Quantum Scientist on the Product team at Xanadu. His work involves creating accessible quantum computing content and PennyLane features for the community, as well as spamming GIFs in our Slack channels.',
    image: '/images/authors/isaac_de_vlugt.jpeg',
  },
  {
    name: 'Isidor Schoch',
    bio: 'Isidor is a Quantum Engineering MSc student at ETH Zurich. He is passionate about exploring the connections between mathematics, physics and computer science. Besides his studies, he currently also works in the PennyLane performance team as a summer resident.',
    image: '/images/authors/isidor_schoch.jpg',
  },
  {
    name: 'Ivana Kurečić',
    bio: "Ivana is an EU-based quantum physicist at Xanadu's Community Team. With background spanning the spectrum of physics and political science, they focus on the adoption and implementation of innovative technologies. More (but infrequently updated) info can be found on happyturtlethings.net.",
    image: '/images/authors/ivana_kurecic.png',
  },
  {
    name: 'Jack Ceroni',
    bio: 'Jack is currently a second-year undergraduate student at the University of Toronto, pursuing a degree in pure mathematics. Broadly, his interests are related to simulating quantum systems with quantum computers, mathematical abstraction, and philosophy.',
    image: '/images/authors/jack_ceroni.jpg',
  },
  {
    name: 'Jack Stephen Baker',
    bio: 'Jack is a quantum algorithms researcher at Agnostiq specializing in quantum machine learning and optimization.',
    image: '/images/authors/jack_stephen_baker.png',
  },
  {
    name: 'Jacob Senior-Williams',
    bio: 'Jacob is a Brexit Island escapee and now Senior Earth Observation Data Scientist at S&T in Delft. His focus is on the utilisation of remote sensing data to monitor the Earth and beyond...',
    image: '/images/authors/jacob_senior-williams.png',
  },
  {
    name: 'James Ellis',
    bio: 'James is a software developer based in London with a university background in Physics and AI.',
    image: '/images/authors/james_ellis.jpg',
  },
  {
    name: 'Jay Soni',
    bio: "Jay completed his BSc. in Mathematical Physics from the University of Waterloo and currently works as a Quantum Software Developer at Xanadu. Fun fact, you will often find him sipping on a Tim Horton's IceCapp while he is coding.",
    image: '/images/authors/jay_soni.png',
  },
  {
    name: 'Joana Fraxanet',
    bio: 'Joana is a PhD student at The Institute of Photonic Sciences in Barcelona, where she studies quantum many-body systems. She is also currently working as a summer resident at Xanadu.',
    image: '/images/authors/joana_fraxanet.jpeg',
  },
  {
    name: 'Johannes Jakob Meyer',
    bio: "Johannes Jakob is a PhD student in Jens Eisert's group at the FU Berlin. He strives to establish hard facts about near-term quantum devices and convert those into useful applications. His personal website is website johannesjakobmeyer.com.",
    image: '/images/authors/johannes_jakob_meyer.png',
  },
  {
    name: 'Joost Bus',
    bio: 'I am a MSc student in Quantum Engineering at ETH Zürich who likes to explore how to wield quantum physics for technology. This summer, I am working with the architecture team on FlamingPy as a Xanadu resident.',
    image: '/images/authors/joost_bus.webp',
  },
  {
    name: 'Joseph Bowles',
    bio: 'Joseph is a researcher at Xanadu working at the intersection between machine learning, quantum computing and the foundations of quantum information theory.',
    image: '/images/authors/joseph_bowles.jpg',
  },
  {
    name: 'Josh Izaac',
    bio: 'Josh is a theoretical physicist, software tinkerer, and occasional baker. At Xanadu, he contributes to the development and growth of Xanadu’s open-source quantum software products.',
    image: '/images/authors/josh_izaac.png',
  },
  {
    name: 'Juan Felipe Carrasquilla Alvarez',
    bio: 'Juan is a physicist interested in ideas at the intersection of condensed matter theory, artificial intelligence, and quantum computing.',
    image: '/images/authors/juan_felipe_carrasquilla_alvarez.png',
  },
  {
    name: 'Juan Miguel Arrazola',
    bio: 'Juan Miguel is the Head of Algorithms at Xanadu. His work focuses on identifying applications of quantum computing by developing quantum algorithms and software for simulating molecules and materials.',
    image: '/images/authors/juan_miguel_arrazola.jpg',
  },
  {
    name: 'Katharine Hyatt',
    bio: 'Katharine Hyatt is scientist on the Amazon Braket team. Previously, she worked at the Center for Computational Quantum Physics at the Flatiron Institute, a division of the Simons Foundation. Past research focused on developing new numerical methods to understand 2D correlated electronic systems, and finding interesting applications in condensed matter physics for these methods, tensor networks, exact diagonalization, and quantum Monte Carlo. She previously studied at the University of California, Santa Barbara, where she received her PhD in physics, and at the University of Waterloo, from which she holds an Honours BSc in Mathematical Physics. She also moonlights as a sometime Julia language and package developer.',
    image: '/images/authors/katharine_hyatt.jpg',
  },
  {
    name: 'Kaur Kristjuhan',
    bio: 'Kaur Kristjuhan is the head of quantum computing at Molecular Quantum Solutions. His focus is on developing algorithms on NISQ devices for chemistry applications.',
    image: '/images/authors/kaur_kristjuhan.jpeg',
  },
  {
    name: 'Korbinian Kottmann',
    bio: 'Korbinian is a Quantum Scientist at Xanadu, interested in quantum simulation and quantum software.',
    image: '/images/authors/korbinian_kottmann.jpg',
  },
  {
    name: 'Lana Bozanic',
    bio: ' Lana is a Mathematical Physics undergraduate at the University of Waterloo. She has spent time pursuing her research interests in quantum optics at the Institute for Quantum Computing (IQC) & the University of Ottawa, as well as particle physics at the University of Toronto.',
    image: '/images/authors/lana_bozanic.jpeg',
  },
  {
    name: 'Lara Watson',
    bio: 'Lara is the Senior Marketing Lead at Xanadu. She is an experienced marketing and administrative professional that you may also find teaching a yoga class occasionally.',
    image: '/images/authors/lara_watson.jpg',
  },
  {
    name: `Lee J. O'Riordan`,
    bio: `Lee J. O'Riordan is a theoretical physicist, a Senior Quantum Software Developer, and the PennyLane Technical Lead at Xanadu. Lee holds a PhD in ultracold quantum systems and computational physics from the Okinawa Institute of Science and Technology Graduate University, Japan. When not writing C++ or Python, he can be found playing the guitar.`,
    image: `/images/authors/lee_o'riordan.png`,
  },
  {
    name: 'Lillian Frederiksen',
    bio: `Lillian's educational background is in experimental condensed matter physics. She has, however, traded in her cryostat for a Python IDE and is working on PennyLane as a quantum software developer at Xanadu.`,
    image: '/images/authors/lillian_frederiksen.png',
  },
  {
    name: `Ludmila Botelho`,
    bio: `Ludmila is a Ph.D. student at IITiS PAN, where she researches quantum optimization algorithms and their applications. She is also a summer resident at Xanadu.`,
    image: `/images/authors/ludmila_botelho.jpg`,
  },
  {
    name: 'Luis Mantilla Calderon',
    bio: 'Luis is a summer resident at Xanadu. He works in quantum error correction and is interested in QML, quantum compilation, and BCI technology.',
    image: '/images/authors/luis_mantilla.jpg',
  },
  {
    name: 'Maggie Li',
    bio: 'Maggie is a student interested in how quantum computing and information theory can be applied to and inform our understanding of biology.',
    image: '/images/authors/maggie_li.jpg',
  },
  {
    name: 'Maria Schuld',
    bio: "Maria leads Xanadu's quantum machine learning team and is a seasoned PennyLane developer.",
    image: '/images/authors/maria_schuld.jpg',
    profileUrl: 'https://www.pennylane.ai/profile/mariaschuld',
  },
  {
    name: 'Mark Nicholas Jones',
    bio: 'Mark Nicholas Jones is the CEO and co-founder of Molecular Quantum Solutions. Molecular Quantum Solutions provides a software platform for calculating thermodynamic properties of molecules, with applications in the pharmaceutical and agrochemical industries.',
    image: '/images/authors/mark_nicholas_jones.png',
  },
  {
    name: 'Matija Medvidović',
    bio: 'Matija is a PhD student at Columbia University and the Flatiron Institute in New York. He works with machine learning methods to study quantum many-body physics and quantum computers. He is currently a part of the Xanadu Residency Program. He is a firm believer in keeping bios short and concise.',
    image: '/images/authors/matija_medvidovic.jpeg',
  },
  {
    name: 'Matteo Paltenghi',
    bio: 'Matteo Paltenghi is a researcher specializing in software engineering with a focus on quantum software testing to enhance the reliability and robustness of quantum computing platforms. He is currently pursuing his PhD at the University of Stuttgart, Germany.',
    image: '/images/authors/matteo_paltenghi.png',
  },
  {
    name: 'Matthew Silverman',
    bio: "Matthew is a quantum software developer at Xanadu. When he's not in the quantum world, you can usually find him making music or sipping coffee with a book in hand.",
    image: '/images/authors/matthew_silverman.jpg',
  },
  {
    name: 'Maurice Weber',
    bio: 'Maurice is a summer resident at Xanadu and a PhD student at ETH Zürich. He is interested in the intersection of Machine Learning and Quantum Computing.',
    image: '/images/authors/maurice_weber.jpeg',
  },
  {
    name: 'Nathan Killoran',
    bio: 'Nathan is the Chief Technical Officer—Software at Xanadu. You might recognize him from his work on PennyLane, quantum machine learning, and founding the famous QHack Meme Contest.',
    image: '/images/authors/nathan_killoran.jpg',
  },
  {
    name: 'Nathan Shammah',
    bio: `Nathan Shammah is the Chief Technology Officer of Unitary Fund, where he leads the technical staff in research projects that develop open-source software tools for the quantum tech community, such as Mitiq and Metriq. He holds a PhD in Physics from the University of Southampton, UK. He is interested in the interplay between collective effects and dissipative dynamics in many-body quantum systems, quantum error mitigation, open-source software and open hardware, science policy and deep tech in general. He is co-founder and vice-president of the Association of Italian Researchers in Japan and holds visiting positions at the University of Milan, Italy and at RIKEN, Japan.`,
    image: `/images/authors/nathan_shammah.jpg`,
  },
  {
    name: 'Olivia Di Matteo',
    bio: 'Olivia is an Assistant Professor in the Department of Electrical and Computer Engineering at The University of British Columbia. Her research interests are quantum compilation, software, and algorithms.',
    image: '/images/authors/olivia_di_matteo.png',
  },
  {
    name: 'Paul K. Faehrmann',
    bio: 'Paul is a Ph.D. student in the group of Jens Eisert at the Freie Universität Berlin. His research revolves around quantum algorithms both for noisy, near-term devices and larger, fault-tolerant quantum computers',
    image: '/images/authors/paul_k_faehrmann.jpg',
  },
  {
    name: 'Peter-Jan Derks',
    bio: 'Peter-Jan is a PhD student in Berlin supervised by Jens Eisert. He is excited about designing near-term quantum error correction experiments and programming decoders.',
    image: '/images/authors/peter-jan_derks.png',
  },
  {
    name: 'Radoica Draškić',
    bio: 'I am a trained theoretical physicist and a wannabe computer scientist. I am currently working as a summer resident at Xanadu.',
    image: '/images/authors/radoica_draskic.jpg',
  },
  {
    name: 'Richard East',
    bio: 'Richard is a theorist working at Xanadu interested in quantum information theory and its insights into theoretical physics and (quantum) machine learning.',
    image: '/images/authors/richard_east.jpeg',
  },
  {
    name: 'Robert Wezeman',
    bio: 'Robert is a research scientist specializing in quantum algorithms at TNO in the Netherlands. His research focuses on early applications of quantum computing; in particular, he studies applications in machine learning and optimization.',
    image: '/images/authors/robert_wezeman.jpg',
  },
  {
    name: 'Roeland Wiersema',
    bio: 'Roeland is a PhD student at the university of Waterloo and the Vector Institute doing research in the field of variational quantum computing for quantum many-body simulation.',
    image: '/images/authors/roeland_wiersema.jpg',
  },
  {
    name: 'Romain Moyard',
    bio: 'Romain is currently a quantum software developer at Xanadu working on PennyLane. He works on developing useful quantum machine learning tools.',
    image: '/images/authors/romain_moyard.jpg',
  },
  {
    name: 'Santosh Kumar Radha',
    bio: 'Santosh is the head of research and development and product lead at Agnostiq.',
    image: '/images/authors/santosh_kumar_radha.png',
  },
  {
    name: 'Shahnawaz Ahmed',
    bio: 'Shahnawaz works on developing useful machine learning techniques for scientific applications with a focus on quantum information and computing.',
    image: '/images/authors/shahnawaz_ahmed.png',
  },
  {
    name: 'Shaoming Zhang',
    bio: 'Shaoming is a graduate student at the Technical University of Munich and BMW. His research interests are numerical methods for quantum many-body systems and quantum algorithms.',
    image: '/images/authors/shaoming_zhang.png',
  },
  {
    name: 'Shuli Shu',
    bio: 'Shuli works as a quantum software developer at Xanadu, where he focuses on accelerating high-performance quantum software platforms with parallel computation and GPUs. He holds a Ph.D in engineering.',
    image: '/images/authors/shuli_shu.jpeg',
  },
  {
    name: 'Simon Cichy',
    bio: 'Simon is a graduate student in quantum engineering from ETH Zürich. He is interested in quantum machine learning and near-term quantum algorithms in general. More on his personal website https://simoncichy.github.io/about',
    image: '/images/authors/simon_cichy.jpg',
  },
  {
    name: 'Soran Jahangiri',
    bio: 'Soran Jahangiri is a quantum chemist working at Xanadu. His work is focused on developing and implementing quantum algorithms for chemistry applications.',
    image: '/images/authors/soran_jahangiri.png',
  },
  {
    name: 'Stefano Mangini',
    bio: 'Stefano is a PhD student in the Quantum Information Theory Group at the University of Pavia (Italy), and he’s interested in the theory and software development of quantum computing and quantum machine learning algorithms.',
    image: '/images/authors/stefano_mangini.jpg',
  },
  {
    name: 'Sukin Sim',
    bio: 'Sukin (Dylan) completed their PhD in Chemical Physics at Harvard University and currently works as a quantum research scientist. Their research interests include the development of algorithms and circuit compilation schemes for fault-tolerant quantum computers.',
    image: '/images/authors/sukin_sim.png',
  },
  {
    name: 'Theodor Isacsson',
    bio: "Theodor is a quantum software engineer based in Vancouver. He's currently at D-Wave Systems, having previously worked on the quantum software stack at Xanadu, where he contributed to packages such as Strawberry Fields, The Walrus and PennyLane.",
    image: '/images/authors/theodor_isacsson.jpg',
  },
  {
    name: 'Thomas Bromley',
    bio: 'Thomas is a quantum scientist working at Xanadu. His work is focused on developing software to execute quantum algorithms on simulators and hardware.',
    image: '/images/authors/thomas_bromley.png',
  },
  {
    name: 'Thomas Cervoni',
    bio: 'Thomas Cervoni has a mathematical physics and business background. At Quantinuum, Thomas contributes to developing educational content for the community; you can contact him if you have any questions/suggestions.',
    image: '/images/authors/thomas_cervoni.jpg',
  },
  {
    name: 'Thomas Hubregtsen',
    bio: 'Thomas is a researcher working on the full big data and machine learning pipeline. He previously worked at IBM Research, BMW Research and Google X. He is currently co-founding a startup while finishing up his PhD in the Eisert Group at FU Berlin. His personal website is https://hubregtsen.com/.',
    image: '/images/authors/thomas_hubregtsen.jpg',
  },
  {
    name: 'Thomas Storwick',
    bio: 'Thomas is a graduate student at the University of Waterloo, studying 2-dimensional materials. He is also the co-creator of bloqit, an open source game for building quantum intuition in a fun and competitive way.',
    image: '/images/authors/thomas_storwick.jpg',
  },
  {
    name: 'Utkarsh Azad',
    bio: "Utkarsh is a quantum software researcher at Xanadu, working on making quantum computing more useful and accessible, with a focus on exploring its applications in natural sciences. Whenever he's not tinkering with code, he will be walking miles searching either for fractals in nature or a cup of filter coffee.",
    image: '/images/authors/utkarsh_azad.jpg',
  },
  {
    name: 'Varun Rishi',
    bio: 'Varun is a theoretical and computational chemist. He did his PhD at the University of Florida, developing quantum chemical methods.',
    image: '/images/authors/varun_rishi.png',
  },
  {
    name: 'Vincent Michaud-Rioux',
    bio: 'Vincent is a computational physicist currently serving as a quantum software developer in the PennyLane Performance Team. His role encompasses various aspects of the development cycle, from optimizing quantum gate kernels to facilitating deployment with tools like Spack and platforms like Conda-Forge. Before this, Vincent earned his Ph.D. in computational material physics at McGill University and led the atomistic division of Nanoacademic for several years.',
    image: '/images/authors/vincent_michaud-rioux.png',
  },
  {
    name: 'Yiheng Duan',
    bio: 'Yiheng is an Applied Scientist at AWS Braket. Yiheng works at the intersection of quantum computing and machine learning and can be reached via yiheng@amazon.com.',
    image: '/images/authors/yiheng_duan.jpeg',
  },
  {
    name: 'Yuan Yao',
    bio: 'Yuan holds a MSc in Engineering from IMT Atlantique and a PhD in computer science from Télécom Paris. Her research focuses on designing photonic quantum circuits automatically.',
    image: '/images/authors/yuan_yao.jpeg',
  },
]

export default authors
