import { ReactNode, useState, useEffect } from 'react'
import { Banner, Footer, Navbar } from 'component-library'
import LinkComponent from '../LinkComponent/LinkComponent'
import { useAuth0 } from '@auth0/auth0-react'
import MarkdownText from '../MarkdownText/MarkdownText'
import { NavbarContent, FooterContent, BannerContent } from 'shared-content'
import { gql, useQuery } from '@apollo/client'
import { usePathPrefix } from '../../hooks/usePathPrefix'
import 'boxicons/css/boxicons.min.css'

const GET_USER_HANDLE = gql`
  query GetUserHandle($userId: UserID) {
    profile(userId: $userId) {
      handle
    }
  }
`

interface ILayoutProps {
  children?: ReactNode
}

const Layout = ({ children }: ILayoutProps) => {
  const pathPrefix: string = usePathPrefix()
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0()
  const [userHandle, setUserHandle] = useState<string>()
  const { data, error } = useQuery(GET_USER_HANDLE, {
    variables: {
      userId: user?.sub,
    },
    skip: !user?.sub,
  })

  useEffect(() => {
    const handle = data?.profile?.handle || ''
    if (handle) setUserHandle(handle)
    if (error) console.error(error)
  }, [data, error])

  useEffect(() => {
    const getUpdatedUserHandle = () => {
      setUserHandle(sessionStorage.getItem('userHandle') || userHandle)
    }
    window.addEventListener('userHandleUpdate', getUpdatedUserHandle)
    return () =>
      window.removeEventListener('userHandleUpdate', getUpdatedUserHandle)
  }, [])

  return (
    <>
      <Navbar
        isAuthenticated={isAuthenticated}
        leftItems={NavbarContent.navbarLeft}
        rightItems={NavbarContent.navbarRight}
        mobileButton={NavbarContent.mobileButton}
        LinkComponent={LinkComponent}
        handle={userHandle}
        createAccount={() => {
          loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })
        }}
        logIn={() => {
          loginWithRedirect({
            appState: { returnTo: window.location.pathname },
          })
        }}
        signOut={() => {
          logout({
            logoutParams: {
              returnTo:
                typeof window !== 'undefined'
                  ? `${window.location.origin}${pathPrefix}/`
                  : '',
            },
          })
        }}
      />
      {BannerContent.bannerText.length > 0 && (
        <Banner bannerText={<MarkdownText text={BannerContent.bannerText} />} />
      )}
      {children}
      <Footer
        footerDescription={<MarkdownText text={FooterContent.description} />}
        {...FooterContent}
        LinkComponent={LinkComponent}
      />
    </>
  )
}

export default Layout
