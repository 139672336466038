import { Link } from 'gatsby'
import { HTMLAttributes, ReactNode } from 'react'
import ScrollLink from '../ScrollLink/ScrollLink'
import { skipNoReferrer } from 'shared-utilities'

const OPEN_IN_SAME_TAB_URLS = [
  'docs.pennylane.ai',
  '/datasets',
  '/codebook',
  '/challenges',
  '/search',
]
const SKIP_IN_APP_NAVIGATION_URLS = [
  '/profile',
  '/datasets',
  '/codebook',
  '/challenges',
  '/search',
]

export const skipInAppNavigation = (url: string) => {
  let skip = false
  SKIP_IN_APP_NAVIGATION_URLS.forEach((fragment) => {
    if (url.includes(fragment)) skip = true
  })
  return skip
}

export interface ILinkComponentProps extends HTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode | string
  href: string
  className?: string
  id?: string
  tabIndex?: number
  openInCurrentTab?: boolean
  testId?: string
  scrollOffset?: number
  onClick?: () => void
}

export default function LinkComponent({
  children,
  href,
  className = '',
  id = '',
  openInCurrentTab = false,
  tabIndex = 0,
  testId = '',
  onClick,
  scrollOffset,
  ...props
}: ILinkComponentProps) {
  if (href.startsWith('/') && !skipInAppNavigation(href)) {
    return (
      <Link
        {...props}
        tabIndex={tabIndex}
        to={href}
        className={className}
        data-testid={testId ? testId : 'internal-link'}
        id={id}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  }

  const onPage = href.startsWith('#')
  const openLinkInCurrentTab =
    onPage ||
    openInCurrentTab ||
    OPEN_IN_SAME_TAB_URLS.some((domain) => href.includes(domain))

  if (onPage) {
    return (
      <ScrollLink
        to={href}
        className={className}
        {...props}
        data-testid={testId ? testId : 'external-link'}
        offset={scrollOffset}
        onClick={onClick}
      >
        {children}
      </ScrollLink>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      {...props}
      tabIndex={tabIndex}
      href={href}
      // Open the link in a new page
      target={openLinkInCurrentTab ? '' : '_blank'}
      // Add noopener and noreferrer for security reasons
      rel={
        openLinkInCurrentTab
          ? ''
          : `noopener ${skipNoReferrer(href) ? '' : 'noreferrer'}`
      }
      className={className}
      data-testid={testId ? testId : 'external-link'}
      id={id}
      onClick={onClick}
    >
      {children}
    </a>
  )
}
