import { SpotlightTheme } from './types'

const theme_1: SpotlightTheme = {
  border: '#CC00CC',
  background: '#FDFAFD',
  title: '#550055',
  description: '#012343',
  link: '#550055',
}

const theme_2: SpotlightTheme = {
  border: '#0274DE',
  background: '#F7FAFC',
  title: '#0271D9',
  description: '#002445',
  link: '#0271D9',
}

export const spotlightTheme = {
  theme_1,
  theme_2,
}
