const ENVIRONMENT_URLS = {
  LOCAL: {
    SITE_URL: 'http://localhost:8000',
    API_URL: `https://dev.cloud.pennylane.ai`,
  },
  DEV: {
    SITE_URL: 'https://dev.pennylane.ai',
    API_URL: `https://dev.cloud.pennylane.ai`,
  },
  STAGING: {
    SITE_URL: 'https://staging.pennylane.ai',
    API_URL: `https://staging.cloud.pennylane.ai`,
  },
  PROD: {
    SITE_URL: 'https://pennylane.ai',
    API_URL: `https://cloud.pennylane.ai`,
  },
}

const ENVIRONMENT_CONFIG = {
  LOCAL: {
    siteURL: ENVIRONMENT_URLS.LOCAL.SITE_URL,
    auth0: {
      enabled: true,
      domain: 'auth.dev.cloud.pennylane.ai',
      clientId: '8BZsYkBm1sosGoG0pdpAaQCPI3B1HJaF',
      audience: ENVIRONMENT_URLS.LOCAL.API_URL,
    },
    graphql: `${ENVIRONMENT_URLS.DEV.API_URL}/graphql`,
    widget_url:
      'https://widget.dev.cloud.pennylane.ai/command-palette/assets/js/widget.js',
  },
  DEV: {
    siteURL: ENVIRONMENT_URLS.DEV.SITE_URL,
    auth0: {
      enabled: true,
      domain: 'auth.dev.cloud.pennylane.ai',
      clientId: '8BZsYkBm1sosGoG0pdpAaQCPI3B1HJaF',
      audience: ENVIRONMENT_URLS.DEV.API_URL,
    },
    graphql: `${ENVIRONMENT_URLS.DEV.API_URL}/graphql`,
    widget_url:
      'https://widget.dev.cloud.pennylane.ai/command-palette/assets/js/widget.js',
  },
  STAGING: {
    siteURL: ENVIRONMENT_URLS.STAGING.SITE_URL,
    auth0: {
      enabled: true,
      domain: 'auth.staging.cloud.pennylane.ai',
      clientId: 'ckfpK2PScCLcVQIeLn9LM44zq1PUzkAS',
      audience: ENVIRONMENT_URLS.STAGING.API_URL,
    },
    graphql: `${ENVIRONMENT_URLS.STAGING.API_URL}/graphql`,
    widget_url:
      'https://widget.staging.cloud.pennylane.ai/command-palette/assets/js/widget.js', // This needs to be updated and point to the staging widget
  },
  PROD: {
    siteURL: ENVIRONMENT_URLS.PROD.SITE_URL,
    auth0: {
      enabled: true,
      domain: 'auth.cloud.pennylane.ai',
      clientId: 'SXdkhNsiLT0GdrOTFAR2gJ5tq8oGVcg3',
      audience: ENVIRONMENT_URLS.PROD.API_URL,
    },
    graphql: `${ENVIRONMENT_URLS.PROD.API_URL}/graphql`,
    widget_url:
      'https://widget.cloud.pennylane.ai/command-palette/assets/js/widget.js',
  },
}

export const getCurrentEnv = () => {
  const ORIGIN_URL = typeof window !== 'undefined' ? `${window.origin}` : ''
  const currentEnv =
    process.env.DEV ||
    (ORIGIN_URL.length > 0 &&
      ENVIRONMENT_CONFIG.DEV.siteURL.includes(ORIGIN_URL))
      ? 'DEV'
      : process.env.STAGING ||
        (ORIGIN_URL.length > 0 &&
          ENVIRONMENT_CONFIG.STAGING.siteURL.includes(ORIGIN_URL))
      ? 'STAGING'
      : process.env.PROD ||
        (ORIGIN_URL.length > 0 &&
          ENVIRONMENT_CONFIG.PROD.siteURL.includes(ORIGIN_URL))
      ? 'PROD'
      : 'LOCAL'
  return currentEnv
}

export const getCurrentEnvConfig = () =>
  ENVIRONMENT_CONFIG[getCurrentEnv() as keyof typeof ENVIRONMENT_CONFIG]
