import * as Sentry from '@sentry/gatsby'
import { getCurrentEnv } from './config'

Sentry.init({
  dsn: 'https://3c24496eb71a40afb43e387324fbbf3e@o280551.ingest.sentry.io/4504606283268096',
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 0.1,
  // Capture 1% of all replays,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.01,
  environment: getCurrentEnv(),
  enabled: getCurrentEnv() !== 'LOCAL',
})
