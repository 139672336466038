import React, { ReactNode } from 'react'

export interface IScrollLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode
  to: string
  offset?: number
  id?: string
  className?: string
  tabIndex?: number
  testId?: string
  onClick?: () => void
}

const ScrollLink = ({
  children,
  to,
  offset = 150, // Default offset is 150px to account for the navbar + banner
  id = '',
  className = '',
  tabIndex = 0,
  testId = '',
  onClick,
  ...props
}: IScrollLinkProps) => {
  const windowPresent = typeof window !== 'undefined'
  const isHash = to.startsWith('#')
  return (
    <a
      id={id}
      className={`ScrollLink ${className}`}
      tabIndex={tabIndex}
      href={to}
      onClick={(e) => {
        if (windowPresent && isHash) {
          e.preventDefault()
          const el = document.querySelector(to)
          if (el) {
            const rect = el.getBoundingClientRect()
            const scrollTop =
              window.scrollY || document.documentElement.scrollTop
            const top = rect.top + scrollTop - offset
            window.scrollTo({ top, behavior: 'smooth' })
            window.history.pushState({}, '', to)
          } else {
            window.location.href = to
          }
        }
        // Default is not prevented if window is undefined
        onClick?.()
      }}
      data-testid={testId ? testId : 'internal-link'}
      {...props}
    >
      {children}
    </a>
  )
}

export default ScrollLink
