import { navigate } from '@reach/router'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import { getCurrentEnvConfig, getCurrentEnv } from '../../../config'
import { usePathPrefix } from '../../hooks/usePathPrefix'

const auth0Config = getCurrentEnvConfig().auth0

export const onRedirectCallback = (appState?: AppState) => {
  if (appState?.returnTo) {
    window.location.href = appState?.returnTo
  } else navigate('/', { replace: true })
}

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const pathPrefix = usePathPrefix()

  const getRedirectURI = () =>
    typeof window !== 'undefined'
      ? `${window.location.origin}${pathPrefix}${
          getCurrentEnv() !== 'LOCAL' ? '/redirect/' : ''
        }`
      : ''

  return auth0Config.enabled ? (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        audience: auth0Config.audience,
        redirect_uri: getRedirectURI(),
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  ) : (
    children
  )
}

export default AuthProvider
